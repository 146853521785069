
/** @jsx jsx */
import { jsx } from 'theme-ui'
import * as THREE from 'three'
import { Suspense, useState } from 'react'
import { Canvas,useFrame } from 'react-three-fiber'
import { OrbitControls,softShadows, useGLTF,Html,Text } from '@react-three/drei'
softShadows()

function Model(){
  const {nodes} = useGLTF('/profile.glb');
  return (
    <group dispose={null}>
      <object3D
        name="planet001"
        position={[0, -3, 0]}
        rotation={[0, 0,0]}
        scale={[0.1, 0.1, 0.1]}
      >

        {          
          Object.values(nodes).map(function(d){
            if(d.type === "Mesh")
            {
                return (                       
                    <mesh 
                      key ={d.name}
                      name= {d.name}  
                      castShadow
                    >
                    <bufferGeometry attach="geometry" {...d.geometry} />              
                    <meshStandardMaterial
                      attach="material"
                      {...d.material} 
                      roughness={1}                   
                      metalness ={0.2}
                    />
                  </mesh>
                )
            }
            else{
                return null;
            }
          })
        }
      </object3D>
    </group>
  )
}


const Plane = () => (
  <mesh rotation={[-Math.PI / 2, 0, 0]} position={[0, -2.8, 0]} receiveShadow>
    <planeBufferGeometry attach="geometry" args={[100, 100]} />
    <shadowMaterial attach="material" transparent opacity={0.4} />
  </mesh>
)

const Plane2 = () => (
  <mesh rotation={[-Math.PI / 2, 0, 0]} position={[0, -2.8, 0]} >
    <planeBufferGeometry attach="geometry" args={[100, 100]} />
    <meshPhysicalMaterial attach="material" color="#F8B195" roughness={0.8}/> 
  </mesh>
)


const Spin=()=>{
  const [word,setWord] =useState("Loading");
  let count = 0;
  useFrame(() => {
      count++;
      if(count%10===0)
      {
        setWord (word+".");
      }
    }
   )
  return (   
    <group>
      <Text fontSize={0.3}>{word}</Text>
     </group> 
    );
}

export default function App() {
  
  return (


    <Canvas 
     style={{ background: "#F8B195" } } 
      camera={
        { position: [0, 0, 8] ,far: 100}
      
      }
     
      onCreated={({ gl }) => {
        gl.shadowMap.enabled = true
        gl.setPixelRatio(window.devicePixelRatio ||2)
        gl.shadowMap.type = THREE.PCFSoftShadowMap
      }}
    

      >
      <ambientLight intensity={1} />
      <directionalLight
        castShadow
        position={[-10, 8, 10]}
        intensity={0.5}    
        shadow-mapSize-width={1024}
        shadow-mapSize-height={1024}
        shadow-camera-far={50}
        shadow-camera-left={-10}
        shadow-camera-right={10}
        shadow-camera-top={10}
        shadow-camera-bottom={-10}   
      />
    
      <spotLight
        intensity={0.2}
        angle={Math.PI / 3}
        color="#fff7d4"
        position={[-3, 5, 5]}
        penumbra={1}

      />
      <fog attach="fog" args={["#F8B195", 0, 30]} />
 
      <Suspense fallback={<Spin />}>      
        <Model/>
        <Plane />
        <Plane2 />
      </Suspense>

      <OrbitControls 
              autoRotate
              autoRotateSpeed ={0.5}
              enablePan={false}
              enableZoom={true}
              enableDamping
              dampingFactor={ 0.05}
              rotateSpeed={2}
              maxDistance= {8}
              minDistance= {5}
              maxPolarAngle={Math.PI / 1.8}
              minPolarAngle={Math.PI / 3}
              maxAzimuthAngle ={Math.PI / 3}
              minAzimuthAngle ={-Math.PI / 3}/>
    </Canvas>

  )
}

// useGLTF.preload('/profile.glb')